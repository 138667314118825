import Image from "next/image"
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Link from 'next/link';
import useDeviceType from "hooks/useDeviceType";

interface PromoBannerProps {
  backgroundImage: string
  mobileBackgroundImage: string
  backgroundColor: string
  title: string
  titleColor: string
  subtitle: string
  subtitleColor: string
  buttonText: string
  buttonColor: string
  buttonTextColor: string
  buttonUrl: string
  altText: string
}

const PromoBanner: React.FC<PromoBannerProps> = (props) => {
  const {
    backgroundImage,
    mobileBackgroundImage,
    backgroundColor,
    title,
    titleColor,
    subtitle,
    subtitleColor,
    buttonText,
    buttonColor,
    buttonTextColor,
    buttonUrl,
    altText
  } = props;

  const deviceType = useDeviceType();

  return (
    <div className="relative w-full h-[400px] overflow-hidden rounded-lg">
      <Image
        src={backgroundImage}
        alt={altText}
        layout="fill"
        priority={!deviceType.isMobile}
        objectFit="cover"
        className="z-0 hidden md:block"
      />
      <Image
        src={mobileBackgroundImage}
        alt={altText}
        layout="fill"
        priority={deviceType.isMobile}
        objectFit="cover"
        objectPosition="right center"
        className="z-0 block md:hidden"
      />
      <div
        className="absolute inset-0 z-10"
        style={{ backgroundColor: backgroundColor }}
      ></div>
      <div className="absolute inset-0 z-20 flex flex-col justify-between p-8">
        <div className="flex flex-col">
          <h2
            className="text-3xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mb-4 max-w-[60%] smx:max-w-[70%] xxsmx:max-w-[90%] xsmx:max-w-[90%]"
            style={{ color: titleColor }}
          >
            {title}
          </h2>
          <p
            className="text-lg md:text-sm lg:text-base xl:text-lg mb-6 max-w-[60%] smx:max-w-[80%] xxsmx:max-w-[90%] xsmx:max-w-[90%]"
            style={{ color: subtitleColor }}
          >
            {subtitle}
          </p>
        </div>
        <div className="mb-2 md:mb-1">
          <Link href={buttonUrl}>
            <button
              className="px-6 py-3 md:px-4 md:py-2 lg:px-6 lg:py-3 rounded-md text-lg md:text-base lg:text-lg font-semibold w-max"
              style={{ backgroundColor: buttonColor, color: buttonTextColor }}
            >
              {buttonText}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export interface PromoBannersObject {
  firstOrderBanner: PromoBannerProps;
  nonFirstOrderBanner: PromoBannerProps;
  promotionBanner: PromoBannerProps;
}

interface HomepageCampaignBannersProps {
  promoBanners: PromoBannersObject;
}

export default function HomepageCampaignBanners({ promoBanners }: HomepageCampaignBannersProps) {
  const user = useSelector((state: RootState) => state.authEvent.user);

  const hasOrdered = ('order_count' in user) && user.order_count > 0;

  return (
    <section className="bg-white">
      <div className="mx-auto 2xl:mt-6 2xl:mb-2 max-w-[1224px] px-4 mt-3 lg:px-4">
        <div className="flex flex-col lg:flex-row -mx-2">
          <div className="w-full lg:w-1/2 px-2 mb-4 lg:mb-0">
            {!hasOrdered ? (
              <PromoBanner {...promoBanners.firstOrderBanner} />
            ) : (
              <PromoBanner {...promoBanners.nonFirstOrderBanner} />
            )}
          </div>
          <div className="w-full lg:w-1/2 px-2">
            <PromoBanner {...promoBanners.promotionBanner} />
          </div>
        </div>
      </div>
    </section>
  )
}