import React from 'react';
import Link from 'next/link';

interface HomepageVideoProps {
  desktopVideoUrl: string;
  mobileVideoUrl: string;
  videoAltText: string;
}

const HomepageVideo: React.FC<HomepageVideoProps> = ({
  desktopVideoUrl,
  mobileVideoUrl,
  videoAltText
}) => {
  const desktopThumbnailUrl = `${desktopVideoUrl}.webp`;
  const mobileThumbnailUrl = `${mobileVideoUrl}.webp`; 

  const videoSchema = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: videoAltText,
    description: videoAltText,
    thumbnailUrl: [mobileThumbnailUrl, desktopThumbnailUrl],
    uploadDate: new Date().toISOString(),
    contentUrl: desktopVideoUrl,
  };

  return (
    <Link href="/shop" className="block cursor-pointer">
      <div data-background="true" className="snap-start overflow-hidden w-full flex-1 inset-0">
        <script type="application/ld+json">{JSON.stringify(videoSchema)}</script>
        <video
          playsInline
          autoPlay
          muted 
          loop
          poster={mobileThumbnailUrl}
          className="h-full w-full object-cover object-center md:hidden"
        >
          <source src={mobileVideoUrl} type="video/mp4" />
          <meta itemProp="description" content={videoAltText} />
        </video>
        <video
          playsInline
          autoPlay
          muted
          loop
          poster={desktopThumbnailUrl}
          className="h-full w-full object-cover object-center hidden md:block"
        >
          <source src={desktopVideoUrl} type="video/mp4" />
          <meta itemProp="description" content={videoAltText} />
        </video>
      </div>
    </Link>
  );
};

export default HomepageVideo;